import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import {graphql} from "gatsby";

const NotFoundPage = ({data}) => {
    let offersData = data.allStrapiOfferPages.edges;
    return(
    <Layout page="404">
        <Header className="notfound" slogan="Sackgasse...dürfen wir Ihnen weiterhelfen?." offersData={offersData}/>
        <SEO title="404: Not found"/>
        <main className="container notfound pt">
            <div className="row">
                <div className="col-md-offset-4 col-md-8">
                    <h1>Fehler 404 - Diese Seite existiert nicht</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-offset-4 col-md-8 col-sm-12">
                    Diese Seite existiert nicht.
                    <a href="/"><strong> Hier</strong></a> gelangen Sie zur Startseite.
                </div>
            </div>
        </main>
    </Layout>
    )
}

export default NotFoundPage

export const pageQuery = graphql`  
  query ErrorPageQuery {
    allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
 }
`
